
  export default {
    name: 'SocialMediaIcons',
    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        },
      },
    },
  }
